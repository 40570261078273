export default {
    userflow: {
        email: {
            signin: 'B2C_1_UptimeGreen_Signin_2',
            signup: 'B2C_1_UptimeGreen_Signup',
        },
        username: {
            signin: 'B2C_1_UptimeGreen_Signin_Username',
            signup: 'B2C_1_UptimeGreen_Signup_Username',
        },
    },
    authType: "username", // email | username
    colors: {
        uk: "#28a745",
        germany: "#5470c6",
        a1: "#afaf03",
        vivacom: "#ff5722cc",
        error: "#dc3545",
        anomaly: "#ffc0cb",
        france: "#9c27b0",
    },
    counters: [
        {
            label: "Processor % Idle Time",
            value: "processor.percentidletime"
        },
        {
            label: "Memory Committed Bytes",
            value: "memory.committedbytes"
        },
        {
            label: "Memory Available Bytes",
            value: "memory.availablebytes"
        },
        {
            label: "Memory Available MBytes",
            value: "memory.availablembytes"
        },
    ],
    pcw_hosts: [
        { 
            name: "DESKTOP-BOJV3E6"
        },
        {
            name: "DESKTOP-BQTKN13"
        },
        {
            name: "WIN-OKR44TQH8DT" 
        },
        { 
            name: "WIN-8VMTO5MLJ1F" 
        },
    ]
}