import { createApp } from 'vue'
import App from './App.vue'
import Emitter from 'tiny-emitter';
import router from './router'
import store from './store'

import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css';

import axios from 'axios'
import VueAxios from 'vue-axios'

import Toaster from '@meforma/vue-toaster';

import Graph from "graphology";
import Sigma from "sigma";

import DateRangePicker from "@gravitano/vue-date-range-picker";

// navigator.serviceWorker.getRegistrations().then(function (registrations) {
//   for (let registration of registrations) {
//     registration.unregister()
//   }
// })

const app = createApp(App)

app.use(VueAxios, axios)

app.use(BalmUI);
app.use(BalmUIPlus);

app.use(Toaster);

app.use(DateRangePicker);

// msal auth global
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.config.globalProperties.$apiUrl = process.env.VUE_APP_API_URL_PROD
// app.config.globalProperties.$apiUrl = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_PROD;

// sigma chart global
app.config.globalProperties.$sigma = Sigma
app.config.globalProperties.$graph = Graph

// create app
app.use(store).use(router).mount('#app')