import { createStore } from 'vuex';
import config from "../config";

const redirect_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : window.location.port}`;
// const redirect_url = `${window.location.href}`;

const store = createStore({
  state() {
    return {
      account: null,
      msalConfig: {
        auth: {
          clientId: 'a74efe39-21ca-4500-8d2e-13fe22527da9',
          authority:
            `https://uptimegreen.b2clogin.com/uptimegreen.onmicrosoft.com/${config.userflow[config.authType].signin}`,
          knownAuthorities: ['uptimegreen.b2clogin.com'], // You must identify your tenant's domain as a known authority.
          redirectUri: redirect_url, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
        },
        cache: {
          cacheLocation: 'localStorage',
        },
        system: {
          loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                return;
              }
              console.log('loggerCallback', message);
            },
            piiLoggingEnabled: false
          },
        }
      },
      accessToken: "",
      loadingAuthStatus: true,
      dashboardInfos: null,
    };
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setLoadingAuthStatus(state, loadingAuthStatus) {
      state.loadingAuthStatus = loadingAuthStatus;
    },
    setDashboardInfos(state, data) {
      state.dashboardInfos = data;
    }
  },
  actions: {
    setLoadingAuthStatus({ commit }, loadingAuthStatus) {
      commit('setLoadingAuthStatus', loadingAuthStatus);
    },
    setDashboardInfos({ commit }, data) {
      commit('setDashboardInfos', data);
    }
  },
  getters: {
    getAccount(state) {
      return state.account;
    },
    getLoadingAuthStatus(state) {
      return state.loadingAuthStatus;
    },
    getDashboardInfos(state) {
      return state.dashboardInfos;
    }
  }
});

export default store;
