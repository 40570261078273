<template>
  <div class="page--top-app-bar">
    <Topbar />
    <div id="content-main">
      <ui-spinner
        :active="loadingAuthStatus"
        style="
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
        "
      ></ui-spinner>

      <div v-if="!loadingAuthStatus">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";

export default {
  components: { Topbar },
  computed: {
    loadingAuthStatus: {
      get() {
        return this.$store.getters["getLoadingAuthStatus"];
      },
      set(val) {
        console.log("val", val);
        this.$store.dispatch("setLoadingAuthStatus", val);
      },
    },
  },
};
</script>

<style lang="scss">
// @import "@/styles/index.scss";

html,
body {
  margin: 0;
}
</style>
