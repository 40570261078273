<template>
  <div>
    <ui-top-app-bar
      content-selector="#content-main"
      :type="0"
      title="Uptime.Green"
    >
      <template #toolbar="{ toolbarItemClass }">
        <img src="@/assets/EUFunds_logo.png" style="width: 166px;background: white;" :class="toolbarItemClass" />
        <img src="@/assets/OPIC_logo.jpg" style="width: 148px; margin-left: 3px;" :class="toolbarItemClass" />
        <ui-icon-button @click="goSettings" :class="toolbarItemClass" icon="settings"></ui-icon-button>
        
        <span v-if="!account">
          <!-- <ui-icon-button
            :class="toolbarItemClass"
            icon="person_add"
            @click="SignUp"
          ></ui-icon-button>

          <ui-icon-button
            :class="toolbarItemClass"
            icon="login"
            @click="SignIn"
          ></ui-icon-button> -->
        </span>
        <span v-else>
          <ui-button
            icon="person"
            :class="toolbarItemClass"
            @click="openUserMenu = true"
            >{{ account.name }}</ui-button
          >

          <ui-menu-anchor absolute>
            <ui-menu v-model="openUserMenu" position="TOP_RIGHT">
              <ui-menuitem @click="SignOut">
                <ui-icon style="margin-right: 12px">logout</ui-icon> Logout
              </ui-menuitem>
            </ui-menu>
          </ui-menu-anchor>
        </span>
      </template>
    </ui-top-app-bar>
    <p style="position: absolute; top: 48px; padding: 5px 25px; background-color: rgba(66,133,244,.12); border-bottom: 1px solid rgba(66,133,244,.5); font-size: 12px; text-align: right;">
      Софтуерът за автоматично наблюдение, извличане, анализ и визуализация на дистрибутирани софтуерни системи е разработен в изпълнение на Договор № BG16RFOP002-2.040-0632/Sе-01 от 08.11.2021 г. в рамките на реализацията на проект № BG16RFOP002-2.040-0632-С01 с наименование: "Подобряване на производствения капацитет в "ИТренинг" ООД", финансиран от Оперативна програма „Иновации и конкурентоспособност“, съфинансирана от Европейския съюз чрез Европейския фонд за регионално развитие.
      <br />
      Идентификационен номер на софтуера: ITR-UG-V20
    </p>
    
    <div style="height: 52px"></div>
  </div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import config from "../config";

export default {
  data() {
    return {
      openUserMenu: false,
      account: undefined,
      value3: 34,
      errMsg: "dddd",
    };
  },
  async created() {
    this.loadingAuthStatus = true;

    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );

    this.$msalInstance
      .handleRedirectPromise()
      .then(this.handleResponse)
      .catch((err) => {
        console.error(err);
        // this.errMsg = err;
        this.$toast.error(err, { position: "bottom", duration: false });
      });
  },
  mounted() {
    this.handleAccount();
    console.log("config", config);
  },
  computed: {
    loadingAuthStatus: {
      get() {
        return this.$store.getters["getLoadingAuthStatus"];
      },
      set(val) {
        console.log("val", val);
        this.$store.dispatch("setLoadingAuthStatus", val);
      },
    },
  },
  methods: {
    goSettings() {
        this.$router.push("/settings");
    },
    handleAccount() {
      const accounts = this.$msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return;
      }

      this.account = accounts[0];
      this.$store.commit("setAccount", this.account);
      this.$emitter.emit("login", this.account);

      console.log("topbar mounted", this.account);
    },
    handleResponse(redirectResponse) {
      console.log("this.$route", this.$route);
      console.log("location", window.location.href);

      if (window.location.href.indexOf("/signup") != -1) {
        return;
      }
      // if (this.$route.path == "/signup" || this.$route.path == "/signin") {
      //   return;
      // }
      // return;
      console.log("redirectResponse", redirectResponse);

      if (redirectResponse !== null) {
        this.loadingAuthStatus = false;

        const myAccounts = this.$msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        this.$store.commit("setAccount", this.account);

        this.$emitter.emit("login", this.account);
        localStorage.setItem("user_uptime", JSON.stringify(myAccounts[0]));
        this.$router.push("/charts?dashboard=" + redirectResponse.state);
      } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = this.$msalInstance.getAllAccounts()[0];

        var url = window.location.href
        var locationSearch = url.slice(url.indexOf("?"))
        const params = new Proxy(new URLSearchParams(locationSearch), {
          get: (searchParams, prop) => searchParams.get(prop),
        });

        console.log('params.dashboard', params.dashboard)
        const accessTokenRequest = {
          scopes: ["openid"],
          account: account,
          state: params.dashboard == "undefined" ? "topology" : params.dashboard
        };
        // return;
        const that = this;
        that.$msalInstance.acquireTokenRedirect(accessTokenRequest);
        // this.$msalInstance
        //   .acquireTokenSilent(accessTokenRequest)
        //   .then(function (accessTokenResponse) {
        //     // Acquire token silent success
        //     // Call API with token
        //     let accessToken = accessTokenResponse.accessToken;
        //     // Call your API with token
        //     console.log("accessToken", accessToken);
        //   })
        //   .catch(function (error) {
        //     //Acquire token silent failure, and send an interactive request
        //     console.log(error);
        //     that.$msalInstance.acquireTokenRedirect(accessTokenRequest);
        //   });
      }
    },

    async SignIn() {
      const loginRequest = {
        scopes: ["openid"],
      };

      await this.$msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          console.log("response", response);
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          this.$emitter.emit("login", this.account);
          localStorage.setItem("user_uptime", JSON.stringify(myAccounts[0]));
          window.location.href = "/charts";
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },

    async SignUp() {
      const redirect_url = `${window.location.protocol}//${
        window.location.hostname
      }${
        window.location.port ? ":" + window.location.port : window.location.port
      }`;

      const signupUrl = `https://uptimegreen.b2clogin.com/uptimegreen.onmicrosoft.com/oauth2/v2.0/authorize?p=${
        config.userflow[config.authType].signup
      }&client_id=a74efe39-21ca-4500-8d2e-13fe22527da9&nonce=defaultNonce&redirect_uri=${redirect_url}&scope=openid&response_type=code&prompt=login&code_challenge=32f3224`;
      window.location.href = signupUrl;
    },

    async SignOut() {
      localStorage.removeItem("user_uptime");
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
