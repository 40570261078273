import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import config from "../config";

function makeRandom(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/charts',
    name: 'Charts',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Charts.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/network',
    name: 'Network',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Network.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == "Signup") {
    const redirect_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : window.location.port}`;
    // const redirect_url = `${window.location.href}`;

    const code_challenge = makeRandom(10)

    const signupUrl = `https://uptimegreen.b2clogin.com/uptimegreen.onmicrosoft.com/oauth2/v2.0/authorize?p=${config.userflow[config.authType].signup}&client_id=a74efe39-21ca-4500-8d2e-13fe22527da9&nonce=defaultNonce&redirect_uri=${redirect_url}&scope=openid&response_type=code&prompt=login&code_challenge=${code_challenge}`;
    window.location.href = signupUrl;

  } else {
    next(); return;

    if (to.matched.some(record => record.meta.requiresAuth)) {
      let user_uptime = localStorage.getItem('user_uptime')
      let user = user_uptime == "undefined" ? null : JSON.parse(user_uptime)

      if (user == null) {
        next({
          path: '/',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
