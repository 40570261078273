<template>
  <div class="home">
    <h1 style="text-align: center; color: gray; margin-top: 200px">
      <span v-if="!currentAccount">
        <ui-button
          @click="SignIn"
          raised
          style="padding: 25px 35px; width: 40%"
        >
          Login
        </ui-button>
      </span>
      <span v-else> You're logged in as {{ currentAccount.name }}.</span>
    </h1>
  </div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  data() {
    return {
      openUserMenu: false,
      account: undefined,
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
  },
  mounted() {
    if (this.currentAccount) {
      this.$router.push("/charts");
    }
  },
  computed: {
    currentAccount: {
      get() {
        return this.$store.getters["getAccount"];
      },
      set(val) {
        console.log("val", val);
      },
    },
    loadingAuthStatus: {
      get() {
        return this.$store.getters["getLoadingAuthStatus"];
      },
      set(val) {
        console.log("val", val);
        this.$store.dispatch("setLoadingAuthStatus", val);
      },
    },
  },
  methods: {
    handleResponse(resp) {
      console.log("resp", resp);
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code

      if (resp !== null) {
        const myAccounts = this.$msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        this.$emitter.emit("login", this.account);
        localStorage.setItem("user_uptime", JSON.stringify(myAccounts[0]));
        this.$router.push("/charts");
      }
    },

    async SignIn() {
      const loginRequest = {
        scopes: ["openid", "offline_access", "mail.send"],
      };

      this.$msalInstance.loginRedirect(loginRequest);
    },
  },
};
</script>
